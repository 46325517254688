import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-M9C9V49L6C"; // Buraya kendi GA4 Measurement ID'ni gir

ReactGA.initialize(TRACKING_ID);

function AnalyticsTracker() {
    const location = useLocation();

    useEffect(() => {
        // Sayfa değiştiğinde Google Analytics'e bildirim gönder
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return null;
}

export default AnalyticsTracker;
