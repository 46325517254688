import React, { useState } from 'react'
import style from "./Navigation.module.css"
import { Link, useLocation } from 'react-router-dom'
import ReactGA from "react-ga4";

const Navigation = ({hamburgerIcon}) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
};
const handleRedirect = () => {
  window.location.href = 'https://app.talent14.com/';
  ReactGA.event({
    category: "Navigation",
    action: "Sisteme Giriş Butonu Tıklandı",
    label: "Sisteme Giriş"
});
};
  return (
    <nav className={`${style.navLinks}`}>
      <div className={`${style.navItemsContainer}`}>
    <div className={`${style.navItems}`}>
    <Link className={`${style.navItem} ${
            location.pathname === "/" ? style.activeNavItem : ""
          }`} to="/">Anasayfa</Link>
    <Link className={`${style.navItem} ${
            location.pathname === "/platform" ? style.activeNavItem : ""
          }`} to="/platform">Platform</Link>
    <Link className={`${style.navItem} ${
            location.pathname === "/pricing" ? style.activeNavItem : ""
          }`} to="/pricing">Fiyatlandırma</Link>
  </div>
      </div>
    <button className={`${style.navButton} m-0 p-0`} onClick={handleRedirect}><span className={`${style.btnText}`}>Sisteme Giriş</span></button>
    <div className={style.navItemsContainerMobil}>
            <div className={`${style.navItemsMobil} ${isMenuOpen ? style.active : ''}`}>
                <Link className={style.navItemMobil} to="/">Anasayfa</Link>
                <Link className={style.navItemMobil} to="/platform">Platform</Link>
                <Link className={style.navItemMobil} to="/pricing">Fiyatlandırma</Link>
            </div>
            <div className={style.hamburgerIcon} onClick={toggleMenu}>
              <img className={style.hamburgerIconImg} src={hamburgerIcon} alt="" />
            </div>
        </div>
  </nav>
  )
}

export default Navigation